import axios from 'axios';

export default {
  namespaced: true,

  state: {
    warehouses: [],
    warehouse: {},
  },

  getters: {
    warehouses(state) {
      return state.warehouses;
    },
    warehouse(state) {
      return state.warehouse;
    },
  },

  mutations: {
    setWarehouses(state, value) {
      state.warehouses = value;
    },
    setWarehouse(state, value) {
      state.warehouse = value;
    },
  },

  actions: {
    async getWarehouses({ commit }) {
      const { data } = await axios.get('/api/warehouses');
      commit('setWarehouses', data);
    },
    async postWarehouse({ commit }, warehouse) {
      const { data } = await axios.post('/api/warehouses', warehouse);
      commit('setWarehouse', data);
    },
  },
};
