<template>
  <div
  class="bg-gray-50 dark:bg-slate-800 dark:text-white overflow-x-hidden
  min-h-screen flex flex-col justify-between items-center">
    <div class="flex w-full" :class="authenticated && isSuperAdmin ? 'flex-col lg:flex-row grow' : 'flex-col'">
      <backoffice-navbar font-case="capitalize" v-if="authenticated && isSuperAdmin" />
      <nav-bar font-case="capitalize" v-if="authenticated && !isSuperAdmin" />
      <div
        class="flex flex-col"
        :class="authenticated && isSuperAdmin ? 'w-full lg:w-3/4 xl:w-4/5 h-screen overflow-y-scroll' : 'w-full'"
      >
        <router-view />
      </div>
    </div>
    <footer-bar />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import NavBar from '@/components/base/NavBar.vue';
import FooterBar from '@/components/base/FooterBar.vue';
import BackofficeNavbar from '@/components/base/BackofficeNavbar.vue';

export default {
  components: {
    NavBar,
    FooterBar,
    BackofficeNavbar,
  },
  mounted() {
    if (!localStorage.getItem('theme')) {
      const soTheme = window.matchMedia('(prefers-color-scheme: dark)') ? 'dark' : 'light';
      localStorage.setItem('theme', soTheme);
      return;
    }
    const lsTheme = localStorage.getItem('theme');
    const element = document.body;
    element.removeAttribute('class');
    element.classList.add(lsTheme);
  },
  computed: {
    ...mapGetters('auth', ['authenticated', 'isSuperAdmin']),
  },
};
</script>
