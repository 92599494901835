import axios from 'axios';

export default {
  namespaced: true,

  state: {
    measurementUnits: [],
    measurementUnit: {},
  },

  getters: {
    measurementUnits(state) {
      return state.measurementUnits;
    },
    measurementUnit(state) {
      return state.measurementUnit;
    },
  },

  mutations: {
    setMeasurementUnits(state, value) {
      state.measurementUnits = value;
    },
    setMeasurementUnit(state, value) {
      state.measurementUnit = value;
    },
  },

  actions: {
    async getMeasurementUnits({ commit }) {
      const { data } = await axios.get('/api/uoms');
      commit('setMeasurementUnits', data);
    },
    async postMeasurementUnit({ commit }, measurementUnit) {
      const { data } = await axios.post('/api/uoms', measurementUnit);
      commit('setMeasurementUnit', data);
    },
    async putMeasurementUnit({ commit }, { id, ...remaining }) {
      const { data } = await axios.put(`/api/uoms/${id}`, remaining);
      commit('setMeasurementUnit', data);
      return data;
    },
  },

};
