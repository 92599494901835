<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" :class="[iconSize, iconColor]">
    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
  </svg>
</template>

<script>
export default {
  name: 'DropdownArrow',
  props: {
    iconSize: {
      type: String,
      default: 'w-4 h-4',
    },
    iconColor: {
      type: String,
      default: 'fill-gray-800 dark:fill-gray-100',
    },
  },
};
</script>
