<template>
  <div class="flex space-x-2 xl:space-x-3 2xl:space-x-5 items-center nav">
    <language-select />
    <button
      @click="doAction()"
      class="border border-gray-300 shadow-md rounded-md fill-gray-400 dark:fill-gray-200 p-1.5"
    >
      <log-out-icon v-if="authenticated" class="w-4 h-4" />
      <log-in-icon v-else class="w-4 h-4" />
    </button>
    <div
      class="switch"
      @click="toggleDarkMode()"
      @keyup="toggleDarkMode()"
    >
      <sun-icon class="icon dark:fill-gray-300 fill-white" />
      <moon-icon class="icon fill-gray-300 dark:fill-white" />
  </div>
    <router-link to="/profile" v-if="!isSuperAdmin" class="link border shadow-md rounded-md p-1.5">
      <user-icon icon-size="w-4 h-4" />
    </router-link>
    <router-link to="/cart" v-if="!isSuperAdmin" class="link border shadow-md rounded-md p-1.5">
      <div class="relative flex items-center cursor-pointer">
        <p class="rounded-full w-2 h-2 flex justify-center bg-main text-white text-xs bottom-1 left-2
        absolute z-20 items-center font-sans-serif font-bold p-3" v-if="productsCount > 0">
          {{ productsCount }}
        </p>
        <cart-icon icon-size="w-4 h-4"></cart-icon>
      </div>
    </router-link>
    <router-link to="/backoffice/cart" v-if="isSuperAdmin"
    class="link border shadow-md rounded-md p-1.5 backoffice">
      <div class="relative flex items-center cursor-pointer">
        <p class="rounded-full w-2 h-2 flex justify-center bg-main text-white text-xs bottom-1 left-2
        absolute z-20 items-center font-sans-serif font-bold p-3" v-if="productsCount > 0">
          {{ productsCount }}
        </p>
        <cart-icon icon-size="w-4 h-4"></cart-icon>
      </div>
    </router-link>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import CartIcon from '@/components/graphic/CartIcon.vue';
import UserIcon from '@/components/graphic/UserIcon.vue';
import LogInIcon from '@/components/graphic/LogInIcon.vue';
import LogOutIcon from '@/components/graphic/LogOutIcon.vue';
import MoonIcon from '@/components/graphic/MoonIcon.vue';
import SunIcon from '@/components/graphic/SunIcon.vue';
import LanguageSelect from '@/components/base/LanguageSelect.vue';

export default {
  components: {
    CartIcon,
    UserIcon,
    LogInIcon,
    LogOutIcon,
    MoonIcon,
    SunIcon,
    LanguageSelect,
  },
  data() {
    return {
      lsTheme: '',
    };
  },
  created() {
    this.startColorMode();
  },
  computed: {
    ...mapGetters('auth', ['authenticated', 'isSuperAdmin']),
    ...mapGetters('cart', ['productsCount']),
  },
  methods: {
    ...mapActions('auth', ['signOut']),
    async doAction() {
      if (!this.authenticated) {
        this.$router.push('/sign-in');
        return;
      }
      await this.signOut();
      this.$router.push('/');
    },
    startColorMode() {
      this.lsTheme = localStorage.getItem('theme');
      this.setColorMode(this.lsTheme);
    },
    toggleDarkMode() {
      this.lsTheme = localStorage.getItem('theme') === 'dark' ? 'light' : 'dark';
      this.setColorMode(this.lsTheme);
    },
    setColorMode(lsTheme) {
      const element = document.body;
      element.removeAttribute('class');
      element.classList.add(lsTheme);
      localStorage.setItem('theme', lsTheme);
    },
  },
};
</script>

<style scoped>
  .switch {
    @apply w-fit cursor-pointer items-center h-8 rounded-md p-1
    flex justify-between shadow-md border border-gray-300;
  }
  .switch:after {
    @apply w-6 h-6 absolute rounded-md content-[''] duration-500 bg-yellow-500
    dark:bg-white/50 dark:translate-x-full;
  }
  .icon {
    @apply w-4 h-4 z-50 m-1 duration-500;
  }
  .link.router-link-active {
    @apply fill-secondary border-secondary
  }
  .link {
    @apply fill-gray-400 dark:fill-gray-200 border-gray-300
  }
  .backoffice.link.router-link-active {
    @apply fill-bee2_yellow border-bee2_yellow
  }
</style>
