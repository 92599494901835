import axios from 'axios';

export default {
  namespaced: true,

  state: {
    productSubcategories: [],
    productSubcategory: {},
  },

  getters: {
    productSubcategories(state) {
      return state.productSubcategories;
    },
    productSubcategory(state) {
      return state.productSubcategory;
    },
  },

  mutations: {
    setProductSubcategories(state, value) {
      state.productSubcategories = value;
    },
    setProductSubcategory(state, value) {
      state.productSubcategory = value;
    },
  },

  actions: {
    async getProductSubcategories({ commit }) {
      const { data } = await axios.get('/api/product-subcategories');
      commit('setProductSubcategories', data);
    },
    async getProductSubcategory({ commit }, id) {
      const { data } = await axios.get(`/api/product-subcategories/${id}`);
      commit('setProductSubcategory', data);
    },
    async postProductSubcategory({ commit }, category) {
      const { data } = await axios.post('/api/product-subcategories', category);
      commit('setProductSubcategory', data);
    },
    async putProductSubcategory({ commit }, { id, ...remaining }) {
      const { data } = await axios.put(`/api/product-subcategories/${id}`, remaining);
      commit('setProductSubcategory', data);
    },
    async getProductSubcategoriesByCategoryId({ commit }, categoryId) {
      const { data } = await axios.get(`/api/product-category/${categoryId}/subcategories`);
      commit('setProductSubcategories', data);
    },
    async postProductSubcategoryByCategoryId({ commit }, { categoryId, subcategory }) {
      const { data } = await axios.post(`/api/product-category/${categoryId}/subcategories`, subcategory);
      commit('setProductSubcategory', data);
    },
  },

};
