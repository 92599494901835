<template>
  <label class="relative flex items-center" for="language">
    <select id="language" name="language" v-model="currentLanguage" @change="changeLanguage"
    class="z-0 w-24 appearance-none cursor-pointer leading-none text-gray-600 dark:text-gray-200
    uppercase bg-transparent text-sm border border-gray-300 shadow-md rounded-md p-1.5">
    {{ currentLanguage }}
      <option :value="locale.code" v-for="locale in locales" :key="locale.code">
        {{ locale.name }}
      </option>
    </select>
    <div class="absolute right-1 pointer-events-none">
      <dropdown-arrow icon-size="w-5 h-5" icon-color="fill-gray-600 dark:fill-gray-200" />
    </div>
  </label>
</template>

<script>
import DropdownArrow from '@/components/graphic/DropdownArrow.vue';

export default {
  components: { DropdownArrow },
  data() {
    return {
      locales: [
        {
          code: 'it',
          name: 'italiano',
        },
        {
          code: 'en',
          name: 'english',
        },
      ],
      currentLanguage: '',
    };
  },
  mounted() {
    this.preferredLanguage();
  },
  methods: {
    preferredLanguage() {
      if (localStorage.getItem('locale')) {
        this.currentLanguage = localStorage.getItem('locale');
      }
      this.changeLanguage();
      this.currentLanguage = this.$i18n.locale;
    },
    changeLanguage() {
      if (localStorage.getItem('locale')) {
        this.$i18n.locale = this.currentLanguage;
      }
      localStorage.setItem('locale', this.$i18n.locale);
    },
  },
};
</script>
