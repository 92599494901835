import axios from 'axios';

export default {
  namespaced: true,

  state: {
    productCategories: [],
    productCategory: {},
  },

  getters: {
    productCategories(state) {
      return state.productCategories;
    },
    productCategory(state) {
      return state.productCategory;
    },
    homeProductCategories(state) {
      return state.productCategories.filter((category) => category.in_homepage);
    },
  },

  mutations: {
    setProductCategories(state, value) {
      state.productCategories = value;
    },
    setProductCategory(state, value) {
      state.productCategory = value;
    },
  },

  actions: {
    async getProductCategories({ commit }) {
      const { data } = await axios.get('/api/product-categories');
      commit('setProductCategories', data);
    },
    async getProductCategory({ commit }, id) {
      const { data } = await axios.get(`/api/product-categories/${id}`);
      commit('setProductCategory', data);
    },
    async postProductCategory({ commit }, category) {
      const { data } = await axios.post('/api/product-categories', category);
      commit('setProductCategory', data);
    },
    async putProductCategory({ commit }, { id, ...remaining }) {
      const { data } = await axios.put(`/api/product-categories/${id}`, remaining);
      commit('setProductCategory', data);
    },
  },

};
