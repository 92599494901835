/* eslint-disable max-len */
// import AppDefault from '@/config/app-defaults';
import axios from 'axios';
// eslint-disable-next-line
import {
  setProductTotals, updateProductPrices, setNewQuantity, checkProductQuantity,
} from '@/utils/cart-service';
// import { getVatRate } from '@/utils/product-service'; // eslint-disable-line

export default {
  namespaced: true,

  state: {
    carts: [],
    products: [],
    address: {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      country: '',
      city: '',
      address: '',
      no: '',
    },
    shipping: null,
    payment_method: null,
    isCash: false,
    paymentFee: '',

    cartHasToBeResetted: false,

    deletedPaymentMethod: false,

    updatedOrder: false,

    availableCoupon: null,
  },

  getters: {
    userCart(state, getters, rootState) {
      return rootState.auth.user ? state.carts.find((cart) => cart.userId === rootState.auth.user.id) : null;
    },
    products(state, getters) {
      return getters.userCart ? getters.userCart.products : state.products;
    },
    address(state, getters) {
      return getters.userCart ? getters.userCart.address : state.address;
    },
    productsCount(state, getters) {
      if (state.cartHasToBeResetted) return 0;
      return getters.products.length;
    },
    subTotal(state, getters) {
      return getters.products.reduce((total, product) => total + product.totalPrice, 0);
    },
    subTotalWithTaxes(state, getters) {
      return getters.products.reduce((total, product) => total + product.totalPriceWithVat, 0);
    },
    subTotalWithCouponWithTaxes(state, getters) {
      let perc = 100;
      if (state.availableCoupon) {
        perc -= state.availableCoupon.value;
      }
      return getters.products.reduce((total, product) => {
        // const vatRate = getVatRate(product.vat_id);
        total += (((product.totalPrice * perc) / 100) * (product.vatRate)) / 100; // eslint-disable-line
        return total;
      }, 0);
      // eslint-disable-next-line
      // return getters.products.reduce((total, product) => total + (((product.totalPrice * perc) / 100) * (100 + product.vat_rate)) / 100, 0); // eslint-disable-line original
    },
    roundedSubTotalWithCouponWithTaxes(state, getters) {
      if (getters.subTotalWithCouponWithTaxes - Math.round(getters.subTotalWithCouponWithTaxes) === -1 && getters.subTotalWithCouponWithTaxes % 5 === 0) {
        return getters.subTotalWithCouponWithTaxes;
      }
      const roundedSubTotal = Math.floor(getters.subTotalWithCouponWithTaxes);
      if (roundedSubTotal - Math.round(getters.subTotalWithCouponWithTaxes) === -1 && roundedSubTotal % 5 === 0) {
        return roundedSubTotal;
      }
      return getters.subTotalWithCouponWithTaxes;
    },
    subTotalToPay(state, getters, rootState, rootGetters) {
      if (rootGetters['auth/isExtraCEE'] || getters.isLegal || getters.isCustomerLegal) {
        return getters.subTotal;
      }
      return getters.roundedSubTotalWithCouponWithTaxes;
    },
    shippingWithTaxes(state, getters) {
      if (getters.userCart) {
        // return getters.userCart.shipping ? (getters.userCart.shipping.price * (100 + getters.userCart.shipping.vat_rate)) / 100
        //   : 0;
        return getters.userCart.shipping ? getters.userCart.shipping.priceWithVAT : 0;
      }
      return state.shipping ? state.shipping.priceWithVAT : 0;
    },
    shippingToPay(state, getters, rootState, rootGetters) {
      if (getters.freeShipping) {
        return 0;
      }
      if (rootGetters['auth/isExtraCEE'] || getters.isLegal) {
        return getters.shipping ? getters.shipping.price : null;
      }
      return getters.shippingWithTaxes;
    },
    roundedShippingWithTaxes(state, getters) {
      return Math.round(getters.shippingWithTaxes);
    },
    shippingTaxes(state, getters) {
      return getters.userCart ? getters.shippingWithTaxes - getters.userCart.shipping.price
        : getters.shippingWithTaxes - state.shipping.price;
    },
    total(state, getters) {
      if (getters.userCart && getters.userCart.shipping) {
        return getters.freeShipping ? getters.subTotal : getters.subTotal + getters.userCart.shipping.price;
      }
      return getters.freeShipping ? getters.subTotal : getters.subTotal + state.shipping.price;
    },
    totalWithTaxes(state, getters) {
      if (getters.freeShipping) {
        return getters.subTotalWithTaxes;
      }
      return getters.subTotalWithTaxes + getters.shippingWithTaxes;
    },
    totalWithCouponWithTaxes(state, getters) {
      if (getters.freeShipping) {
        return getters.roundedSubTotalWithCouponWithTaxes;
      }
      return getters.roundedSubTotalWithCouponWithTaxes + getters.shippingWithTaxes;
    },
    totalToPay(state, getters) {
      if (!state.isCash) {
        return getters.totalWithCouponWithTaxes;
      }
      return getters.totalWithCouponWithTaxes + state.paymentFee;
    },
    totalTaxes(state, getters) {
      return getters.totalWithTaxes - getters.total;
    },
    freeShipping(state, getters) {
      return getters.subTotalWithTaxes >= (getters.shipping ? getters.shipping.free_shipping : 15000);
    },
    shipping(state, getters) {
      if (getters.userCart) {
        return getters.userCart.shipping;
      }
      return state.shipping;
    },
    shippingMethodId(state, getters) {
      if (getters.userCart) {
        return getters.userCart.shipping.methodId;
      }
      return state.shipping.methodId;
    },
    paymentMethod(state, getters) {
      if (getters.userCart) {
        return getters.userCart.payment_method;
      }
      return state.payment_method;
    },
    shippingPrice(state, getters) {
      if (getters.userCart) {
        return getters.freeShipping ? 0 : getters.userCart.shipping.price;
      }
      return getters.freeShipping ? 0 : state.shipping.price;
    },

    deletedPaymentMethod(state) {
      return state.deletedPaymentMethod;
    },

    updatedOrder(state) {
      return state.updatedOrder;
    },

    isCash(state) {
      return state.isCash;
    },

    availableCoupon(state) {
      return state.availableCoupon;
    },

    isLegal(state, getters, rootState, rootGetters) {
      // eslint-disable-next-line
      return rootGetters['auth/user'] && rootGetters['auth/user'].customer && rootGetters['auth/user'].customer.type === 'G';
    },

    isCustomerLegal(state, getters, rootState, rootGetters) {
      // eslint-disable-next-line
      return rootGetters['customers/customer'] && rootGetters['customers/customer'].type === 'G';
    },
  },

  mutations: {
    // INIT(state) {
    //   state.tax_rate = AppDefault.tax_rate;
    // },
    SET_USER(state, id) {
      if (state.carts.find((cart) => cart.userId === id)) {
        if (state.products.length > 0) {
          state.carts.find((cart) => cart.userId === id).products = state.products;
          state.products = [];
        }
        return;
      }
      const userCart = {
        userId: id,
        products: [],
        address: {
          firstName: '',
          lastName: '',
          email: '',
          phone: '',
          country: '',
          city: '',
          address: '',
          no: '',
        },
        shipping: null,
        payment_method: null,
      };
      // userCart.tax_rate = AppDefault.tax_rate;
      if (state.products.length > 0) {
        userCart.products = state.products;
        state.products = [];
      }
      state.carts.push(userCart);
    },
    ADD_PRODUCT(state, data) {
      if (state.carts.find((cart) => cart.userId === data.id)) {
        state.carts.find((cart) => cart.userId === data.id).products.push({
          ...data.product,
          note: '',
          key: Math.floor(Math.random() * 10000000),
        });
        return;
      }
      state.products.push({
        ...data.product,
        note: '',
        key: Math.floor(Math.random() * 10000000),
      });
    },
    REMOVE_PRODUCT(state, data) {
      if (state.carts.find((cart) => cart.userId === data.id)) {
        state.carts.find((cart) => cart.userId === data.id).products = state.carts.find(
          (cart) => cart.userId === data.id,
        ).products.filter(
          (product) => product.key !== data.productToRemove.key,
        );
        return;
      }
      state.products = state.products.filter(
        (product) => product.key !== data.productToRemove.key,
      );
    },

    REMOVE_ALL_PRODUCTS(state, id) {
      if (!id) {
        state.products = [];
        return;
      }
      state.carts.find((cart) => cart.userId === id).products = [];
    },
    UPDATE_PRODUCT_QUANTITY(state, data) {
      let product = {};
      if (!data.id) {
        product = state.products.find((item) => item.id === data.payload.productToUpdate.id && data.payload.productToUpdate.isVariant === item.isVariant);
      } else {
        product = state.carts.find((cart) => cart.userId === data.id).products.find((item) => item.id === data.payload.productToUpdate.id && data.payload.productToUpdate.isVariant === item.isVariant);
      }
      product = setNewQuantity(product, data.payload.quantity, data.payload.productToUpdate.quantity);
      product.quantity = checkProductQuantity(product);

      product = setProductTotals(product);
      return product;
    },
    UPDATE_PRODUCT_NOTE(state, data) {
      let product = {};
      if (!data.id) {
        product = state.products.find((item) => item.id === data.payload.productToUpdate.id && data.payload.productToUpdate.isVariant === item.isVariant);
      } else {
        product = state.carts.find((cart) => cart.userId === data.id).products.find((item) => item.id === data.payload.productToUpdate.id && data.payload.productToUpdate.isVariant === item.isVariant);
      }

      product.note = data.payload.note;

      return product;
    },
    UPDATE_PRODUCTS_DISCOUNT_RATE(state, data) {
      const userCart = state.carts.find((cart) => cart.userId === data.id);
      userCart.products.forEach((cartProduct) => {
        let product = cartProduct;
        const discountRule = data.discountRules.find((rule) => rule.product_category_id === product.product_category_id);
        if (discountRule) {
          product = updateProductPrices(product, discountRule);
        }
      });
    },
    UPDATE_ADDRESS(state, data) {
      if (!data.id) {
        state.address = data.address;
        return;
      }
      state.carts.find((cart) => cart.userId === data.id).address = data.address;
    },
    UPDATE_SHIPPING(state, data) {
      if (!data.id) {
        state.shipping = data.shipping;
        return;
      }
      state.carts.find((cart) => cart.userId === data.id).shipping = data.shipping;
    },
    UPDATE_PAYMENT_METHOD(state, data) {
      if (!data.id) {
        state.payment_method = data.method;
        return;
      }
      state.carts.find((cart) => cart.userId === data.id).payment_method = data.method;
    },
    RESET_STATE(state, id) {
      if (!id) {
        Object.assign(state, {
          products: [],
          address: {
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            country: '',
            city: '',
            address: '',
            no: '',
          },
          shipping: null,
        });
        return;
      }
      Object.assign(state.carts.find((cart) => cart.userId === id), {
        products: [],
        address: {
          firstName: '',
          lastName: '',
          email: '',
          phone: '',
          country: '',
          city: '',
          address: '',
          no: '',
        },
        shipping: null,
      });
    },

    CART_HAS_TO_BE_RESETTED(state) {
      state.cartHasToBeResetted = !state.cartHasToBeResetted;
    },

    DELETED_PAYMENT_METHOD(state) {
      state.deletedPaymentMethod = !state.deletedPaymentMethod;
    },

    UPDATED_ORDER(state) {
      state.updatedOrder = !state.updatedOrder;
    },

    UPDATE_IS_CASH(state) {
      state.isCash = !state.isCash;
    },

    SET_PAYMENT_FEE(state, fee) {
      state.paymentFee = fee;
    },

    SET_AVAILABLE_COUPON(state, coupon) {
      state.availableCoupon = coupon;
    },
  },

  actions: {
    // Initialize({ commit }) {
    //   commit('INIT');
    // },
    setUser({ commit }, id) {
      commit('SET_USER', id);
    },
    addProduct({
      commit, dispatch, getters, rootState,
    }, product) {
      dispatch('resetCartIfItHasTo');
      if (!getters.products.find((item) => product.id === item.id && product.isVariant === item.isVariant)) {
        const data = {
          id: rootState.auth.user ? rootState.auth.user.id : null,
          product,
        };
        commit('ADD_PRODUCT', data);
        return;
      }
      const data = {
        id: rootState.auth.user ? rootState.auth.user.id : null,
        payload: {
          productToUpdate: product,
          quantity: '+',
        },
      };
      commit('UPDATE_PRODUCT_QUANTITY', data);
    },
    removeProduct({ commit, rootState }, productToRemove) {
      const data = {
        id: rootState.auth.user ? rootState.auth.user.id : null,
        productToRemove,
      };
      commit('REMOVE_PRODUCT', data);
    },
    removeAllProducts({ commit, rootState }) {
      commit('REMOVE_ALL_PRODUCTS', rootState.auth.user ? rootState.auth.user.id : null);
    },
    updateProductQuantity({ commit, rootState }, payload) {
      const data = {
        id: rootState.auth.user ? rootState.auth.user.id : null,
        payload,
      };
      commit('UPDATE_PRODUCT_QUANTITY', data);
    },
    updateProductNote({ commit, rootState }, payload) {
      const data = {
        id: rootState.auth.user ? rootState.auth.user.id : null,
        payload,
      };
      commit('UPDATE_PRODUCT_NOTE', data);
    },
    updateProductsDiscountRate({ commit, rootState }) {
      const customerGroup = rootState.auth.user.customer.customer_group;
      let discountRules = [];
      axios.get(`/api/discount-rules?customerGroup=${customerGroup.id}`).then((response) => {
        discountRules = response.data;
        const data = {
          id: rootState.auth.user ? rootState.auth.user.id : null,
          discountRules,
        };
        commit('UPDATE_PRODUCTS_DISCOUNT_RATE', data);
      });
    },
    updateAddress({ commit, rootState }, address) {
      const data = {
        id: rootState.auth.user ? rootState.auth.user.id : null,
        address,
      };
      commit('UPDATE_ADDRESS', data);
    },
    updateShipping({ commit, rootState }, shipping) {
      const data = {
        id: rootState.auth.user ? rootState.auth.user.id : null,
        shipping,
      };
      commit('UPDATE_SHIPPING', data);
    },
    updatePaymentMethod({ commit, rootState }, method) {
      const data = {
        id: rootState.auth.user ? rootState.auth.user.id : null,
        method,
      };
      commit('UPDATE_PAYMENT_METHOD', data);
    },
    reset({ commit, rootState }) {
      commit('RESET_STATE', rootState.auth.user ? rootState.auth.user.id : null);
    },
    cartToReset({ commit }) {
      commit('CART_HAS_TO_BE_RESETTED');
    },
    resetCartIfItHasTo({ state, dispatch }) {
      if (state.cartHasToBeResetted) {
        dispatch('reset');
        dispatch('cartToReset');
      }
    },
    deletedPaymentMethod({ commit }) {
      commit('DELETED_PAYMENT_METHOD');
    },
    updatedOrder({ commit }) {
      commit('UPDATED_ORDER');
    },
    updateIsCash({ commit }, fee) {
      commit('UPDATE_IS_CASH');
      commit('SET_PAYMENT_FEE', fee);
    },
    setAvailableCoupon({ commit }, coupon) {
      commit('SET_AVAILABLE_COUPON', coupon);
    },
  },
};
