import { createStore } from 'vuex';
import VuexPersistence from 'vuex-persist';
import auth from './modules/auth';
import productCategories from './modules/productCategories';
import productSubcategories from './modules/productSubcategories';
import productBrands from './modules/productBrands';
import products from './modules/products';
import variants from './modules/variants';
import measurementUnits from './modules/measurementUnits';
import vats from './modules/vats';
import currencies from './modules/currencies';
import attributeTags from './modules/attributeTags';
import attributes from './modules/attributes';
import orders from './modules/orders';
import shippingMethods from './modules/shippingMethods';
import customerGroups from './modules/customerGroups';
import customers from './modules/customers';
import discountRules from './modules/discountRules';
import cart from './modules/cart'; //eslint-disable-line
import shops from './modules/shops';
import images from './modules/images';
import warehouses from './modules/warehouses';
import warehouseTransactions from './modules/warehouse-transactions';
import layouts from './modules/layouts';
import campaigns from './modules/campaigns';

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  key: process.env.VUE_APP_VUEX_PERSISTANCE_KEY || 'tnsf-b2cb',
  reducer: (state) => ({
    cart: state.cart,
    vats: state.vats,
  }), // only some modules
});

export default createStore({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    auth,
    productCategories,
    productSubcategories,
    productBrands,
    products,
    variants,
    measurementUnits,
    vats,
    currencies,
    attributeTags,
    attributes,
    orders,
    shippingMethods,
    customerGroups,
    customers,
    discountRules,
    cart,
    shops,
    images,
    warehouses,
    warehouseTransactions,
    layouts,
    campaigns,
  },
  plugins: [vuexLocal.plugin],
});
