import axios from 'axios';

export default {
  namespaced: true,

  state: {
    shippingMethods: [],
    shippingMethod: {},
  },

  getters: {
    shippingMethods(state) {
      return state.shippingMethods;
    },
    shippingMethod(state) {
      return state.shippingMethod;
    },
  },

  mutations: {
    setShippingMethods(state, value) {
      state.shippingMethods = value;
    },
    setShippingMethod(state, value) {
      state.shippingMethod = value;
    },
  },

  actions: {
    async getShippingMethods({ commit }) {
      const { data } = await axios.get('/api/shipping-methods');
      commit('setShippingMethods', data);
    },
    async getShippingMethod({ commit }, id) {
      const { data } = await axios.get(`/api/shipping-methods/${id}`);
      commit('setShippingMethod', data);
    },
    async postShippingMethod({ commit }, shippingMethod) {
      const { data } = await axios.post('/api/shipping-methods', shippingMethod);
      commit('setShippingMethod', data);
    },
    async putShippingMethod({ commit }, { id, ...remaining }) {
      const { data } = await axios.put(`/api/shipping-methods/${id}`, remaining);
      commit('setShippingMethod', data);
    },
  },

};
