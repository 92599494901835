import axios from 'axios';

export default {
  namespaced: true,

  state: {
    productBrands: [],
    productBrand: {},
  },

  getters: {
    productBrands(state) {
      return state.productBrands;
    },
    productBrand(state) {
      return state.productBrand;
    },
    homeProductBrands(state) {
      return state.productBrands.filter((brand) => brand.in_homepage);
    },
  },

  mutations: {
    setProductBrands(state, value) {
      state.productBrands = value;
    },
    setProductBrand(state, value) {
      state.productBrand = value;
    },
  },

  actions: {
    async getProductBrands({ commit }) {
      const { data } = await axios.get('/api/product-brands');
      commit('setProductBrands', data);
    },
    async getProductBrand({ commit }, id) {
      const { data } = await axios.get(`/api/product-brands/${id}`);
      commit('setProductBrand', data);
    },
    async postProductBrand({ commit }, brand) {
      const { data } = await axios.post('/api/product-brands', brand);
      commit('setProductBrand', data);
    },
    async putProductBrand({ commit }, { id, ...remaining }) {
      const { data } = await axios.put(`/api/product-brands/${id}`, remaining);
      commit('setProductBrand', data);
    },
  },

};
