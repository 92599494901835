import axios from 'axios';

export default {
  namespaced: true,

  state: {
    vats: [],
    vat: {},
  },

  getters: {
    vats(state) {
      return state.vats;
    },
    vat(state) {
      return state.vat;
    },
    vatById: (state) => (id) => state.vats.filter((vat) => vat.id === id),
  },

  mutations: {
    setVats(state, value) {
      state.vats = value;
    },
    setVat(state, value) {
      state.vat = value;
    },
  },

  actions: {
    async getVats({ commit }) {
      const { data } = await axios.get('/api/vats');
      commit('setVats', data);
    },
    async getVat({ commit }, vatSlug) {
      const { data } = await axios.get(`/api/vats/${vatSlug}`);
      commit('setVat', data);
    },
    async postVat({ commit }, vat) {
      const { data } = await axios.post('/api/vats', vat);
      commit('setVat', data);
    },
  },

};
