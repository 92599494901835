import axios from 'axios';

export default {
  namespaced: true,

  state: {
    attributeTags: [],
    selectableAttributeTags: [],
  },

  getters: {
    attributeTags(state) {
      return state.attributeTags;
    },
    selectableAttributeTags(state) {
      return state.selectableAttributeTags;
    },
  },

  mutations: {
    setAttributeTags(state, value) {
      state.attributeTags = value;
    },
    setSelectableAttributeTags(state, value) {
      state.selectableAttributeTags = value;
    },
    pushAttributeTag(state, value) {
      state.attributeTags.push(value);
    },
    spliceAttributeTag(state, value) {
      state.attributeTags.splice(state.attributeTags.findIndex(
        (tag) => tag.id === value.id,
      ), 1, { id: value.id, ...value.attributeTag });
    },
    spliceSelectableAttributeTag(state, value) {
      state.selectableAttributeTags.splice(state.selectableAttributeTags.findIndex(
        (tag) => tag.id === value,
      ), 1);
    },
  },

  actions: {
    async getAttributeTagsByCategoryId({ commit }, categoryId) {
      const { data } = await axios.get(`/api/product-category/${categoryId}/attribute-tags`);
      commit('setAttributeTags', data);
      return data;
    },
    async postAttributeTagsByCategoryId({ commit }, { categoryId, attributeTags }) { // eslint-disable-line
      const { data } = await axios.post(`/api/product-category/${categoryId}/attribute-tags`, attributeTags);
      return data;
    },
    async putAttributeTagsByCategoryId({ commit }, { categoryId, attributeTag }) { // eslint-disable-line
      console.log('Put method called: ', categoryId, attributeTag);
      // TODO: aneble the statements below when put method is implemented on backend
      // eslint-disable-next-line
      // const { data } = await axios.post(`/api/product-category/${categoryId}/attribute-tags`, attributeTag);
      // return data;
    },
    async getAttributeTagsBySubcategoryId({ commit }, subcategoryId) { //eslint-disable-line
      let resp;
      try {
        resp = await axios.get(`/api/product-subcategory/${subcategoryId}/attribute-tags`);
      } catch (err) {
        if (err.response.status === 404) {
          return [];
        }
        throw err;
      }
      commit('setAttributeTags', resp.data);
      return resp.data;
    },
    async postAttributeTagsBySubcategoryId({ commit }, { subCategoryId, attributeTags }) { // eslint-disable-line
      const { data } = await axios.post(`/api/product-subcategory/${subCategoryId}/attribute-tags`, attributeTags);
      return data;
    },
    addAttributeTag({ commit }, attributeTag) {
      commit('pushAttributeTag', attributeTag);
    },
    replaceAttributeTag({ commit }, { id, attributeTag }) {
      commit('spliceAttributeTag', { id, attributeTag });
    },
    clearAttributeTags({ commit }) {
      commit('setAttributeTags', []);
    },
    saveAttributeTags({ commit }, attributeTags) {
      commit('setAttributeTags', (attributeTags));
    },
    saveSelectableAttributeTags({ commit }, attributeTags) {
      commit('setSelectableAttributeTags', (attributeTags));
    },
    removeSelectableAttributeTag({ commit }, id) {
      commit('spliceSelectableAttributeTag', id);
    },
  },

};
