import axios from 'axios';

export default {
  namespaced: true,

  state: {
    currencies: [],
    currency: {},
  },

  getters: {
    currencies(state) {
      return state.currencies;
    },
    currency(state) {
      return state.currency;
    },
  },

  mutations: {
    setCurrencies(state, value) {
      state.currencies = value;
    },
    setCurrency(state, value) {
      state.currency = value;
    },
  },

  actions: {
    async getCurrencies({ commit }) {
      const { data } = await axios.get('/api/currencies');
      commit('setCurrencies', data);
    },
    async getCurrency({ commit }, currencyCode) {
      const { data } = await axios.get(`/api/currencies/${currencyCode}`);
      commit('setCurrency', data);
    },
    async postCurrency({ commit }, currency) {
      const { data } = await axios.post('/api/currencies', currency);
      commit('setCurrency', data);
    },
  },

};
