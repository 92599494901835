<template>
  <div :class="classes" class="flex flex-col w-full space-y-1 text-black dark:text-white">
    <div class="p-3 leading-none text-xl font-normal rounded-lg background text-bee2_text"
    :class="headerColor">
      {{ label }}
    </div>
    <div class="p-4 flex flex-col text-base w-full rounded-lg border border-[#FFD38E] space-y-2">
      <router-link
        class="w-fit leading-none hover:scale-105 origin-left duration-200 hover:text-bee2_text"
        v-for="route in routes" :key="route"
        :to="route.path"
      >
        {{ route.name }}
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      shadowClass: {
        shadow: 'shadow-lg',
        no_shadow: 'shadow-none',
      },
      borderClass: {
        border: 'border',
        no_border: 'border-none',
      },
      caseClass: {
        uppercase: 'uppercase',
        lowercase: 'lowercase',
        capitalize: 'capitalize',
      },
      weightClass: {
        thin: 'font-thin',
        light: 'font-light',
        normal: 'font-normal',
        bold: 'font-bold',
      },
    };
  },
  props: {
    label: {
      type: String,
      default: 'Sezione',
    },
    routes: {
      type: Object,
    },
    headerColor: {
      type: String,
      default: '',
    },
    hasShadow: {
      type: String,
      default: 'no_shadow',
    },
    hasBorder: {
      type: String,
      default: 'no_border',
    },
    fontWeight: {
      type: String,
      default: 'thin',
    },
    fontCase: {
      type: String,
      default: 'capitalize',
    },
  },
  computed: {
    classes() {
      // eslint-disable-next-line max-len
      return `${this.shadowClass[this.hasShadow]} ${this.borderClass[this.hasBorder]} ${this.caseClass[this.fontCase]} ${this.weightClass[this.fontWeight]}`;
    },
  },
};
</script>

<style>
.background {
  background-image: url(../../assets/Bee2_background.svg);
  background-size: cover;
  background-position: center;
}
</style>
