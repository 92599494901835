import axios from 'axios';

export default {
  namespaced: true,

  state: {
    shop: {},
  },

  getters: {
    shop(state) {
      return state.shop;
    },
    isItaly(state) {
      return state.shop.country === 'IT';
    },
  },

  mutations: {
    setShop(state, value) {
      state.shop = value;
    },
  },

  actions: {
    async getShop({ commit }) {
      const { data } = await axios.get('/api/shop');
      commit('setShop', data);
    },
  },

};
