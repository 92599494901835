import axios from 'axios';

export default {
  namespaced: true,

  state: {
    images: [],
    image: {},
  },

  getters: {
    images(state) {
      return state.images;
    },
    image(state) {
      return state.image;
    },
  },

  mutations: {
    setImages(state, value) {
      state.images = value;
    },
    setImage(state, value) {
      state.image = value;
    },
  },

  actions: {
    async postImages({ commit }, images) {
      const { data } = await axios.post('/api/images/upload', images);
      commit('setImage', data);
      return data;
    },
  },

};
