import { createApp } from 'vue';
import * as Sentry from '@sentry/vue';
import Toast, { POSITION, useToast } from 'vue-toastification';
// Import the CSS or use your own!
import 'vue-toastification/dist/index.css';
import axios from 'axios';
import InstantSearch from 'vue-instantsearch/vue3/es';
import 'instantsearch.css/themes/satellite.css';
import App from './App.vue';
import router from './router';
import store from './store';
import i18n from './i18n';
import './css/app.css';

const toast = useToast();

axios.defaults.withCredentials = true;

const toastOptions = {
  icon: false,
  position: POSITION.BOTTOM_LEFT,
};

store.dispatch('shops/getShop').then(() => {});
store.dispatch('shippingMethods/getShippingMethods').then(() => {});

const app = createApp(App)
  .use(i18n)
  .use(store)
  .use(router)
  .use(Toast, toastOptions)
  .use(InstantSearch);

Sentry.init({
  app,
  dsn: 'https://99d26ab26227597799265cece3fe6ea7@o4507867837169664.ingest.de.sentry.io/4507869811966032',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['https://www.egeanet.com', /^\/api\//],
  // Session Replay
  replaysSessionSampleRate: 0.1,

  replaysOnErrorSampleRate: 1.0,

  release: process.env.VUE_APP_SENTRY_RELEASE,
  environment: process.env.NODE_ENV,
});

app.mount('#app');

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status < 308) return; // ignore redirects
    if (error.response.status === 404) { // notify not found and continue
      toast.error('Not Found');
      return;
    }
    if (error.response.status !== 401) { // manage generic errors
      if (error.response.data && error.response.data.message) {
        toast.error('Something has gone wrong with the server.');
      }
      store.dispatch('auth/clearCredentials');
      router.push('/');
      return;
    }
    throw error;
  },
);
