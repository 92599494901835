import axios from 'axios';

export default {
  namespaced: true,

  state: {
    products: [],
    product: {},
    variants: [],
    variant: {},
  },

  getters: {
    products(state) {
      return state.products;
    },
    product(state) {
      return state.product;
    },
    variants(state) {
      return state.variants;
    },
    variant(state) {
      return state.variant;
    },
  },

  mutations: {
    setProducts(state, value) {
      state.products = value;
    },
    setProduct(state, value) {
      state.product = value;
    },
    setVariants(state, value) {
      state.variants = value;
    },
    setVariant(state, value) {
      state.variant = value;
    },
  },

  actions: {
    async getProducts({ commit }) {
      const { data } = await axios.get('/api/products');
      commit('setProducts', data);
    },
    async searchProducts({ commit }, text) {
      const { data } = await axios.get(`/api/products?sku=${text}`);
      commit('setProducts', data);
      return data;
    },
    async getProductsByFilter({ commit }, filter) { // eslint-disable-line
      const { data } = await axios.get(`/api/products?${filter}`);
      return data;
    },
    async getProduct({ commit }, productSku) {
      const { data } = await axios.get(`/api/products/${productSku}`);
      commit('setProduct', data);
      return data;
    },
    async postProduct({ commit }, product) {
      const { data } = await axios.post('/api/products', product);
      commit('setProduct', data);
    },
    async putProduct({ commit }, { id, ...remaining }) { // eslint-disable-line
      const { data } = await axios.put(`/api/products/${id}`, remaining);
      return data;
    },
    async patchDefaultVariantId({ commit }, { id, ...remaining }) { // eslint-disable-line
      const { data } = await axios.patch(`/api/products/${id}/set-default-variant`, remaining);
      return data;
    },
    replaceProduct({ commit }, product) {
      commit('setProduct', product);
    },
  },

};
