export default {
  namespaced: true,

  state: {
    layouts: {
      MUSICA: {
        CardProductHorizontal: {
          header: {
            type: 'attribute',
            value: 'AUTORE',
          },
          body: {
            type: 'field',
            value: 'description',
          },
          footerDetails: [
            {
              type: 'field',
              value: 'productCategoryName',
            },
            {
              type: 'field',
              value: 'productSubcategoryName',
            },
            {
              type: 'field',
              value: 'sku',
            },
            {
              type: 'field',
              value: 'availableFrom',
            },
            {
              type: 'field',
              value: 'productBrandName',
            },
            {
              type: 'attribute',
              value: 'ETICHETTA',
            },
            {
              type: 'attribute',
              value: 'CATALOGO',
            },
          ],
        },
        CardProductVertical: {
          header: {
            type: 'attribute',
            value: 'AUTORE',
          },
          body: {
            type: 'field',
            value: 'description',
          },
          footerDetails: [
            {
              type: 'field',
              value: 'productCategoryName',
            },
            {
              type: 'field',
              value: 'productSubcategoryName',
            },
            {
              type: 'field',
              value: 'sku',
            },
            {
              type: 'field',
              value: 'availableFrom',
            },
            {
              type: 'field',
              value: 'productBrandName',
            },
            {
              type: 'attribute',
              value: 'ETICHETTA',
            },
            {
              type: 'attribute',
              value: 'CATALOGO',
            },
          ],
        },
        CartProduct: {
          header: {
            type: 'attribute',
            value: 'AUTORE',
          },
          body: {
            type: 'field',
            value: 'description',
          },
          footerDetails: [
            {
              type: 'field',
              value: 'productCategoryName',
            },
            {
              type: 'field',
              value: 'productSubcategoryName',
            },
            {
              type: 'field',
              value: 'sku',
            },
            {
              type: 'field',
              value: 'availableFrom',
            },
            {
              type: 'field',
              value: 'productBrandName',
            },
            {
              type: 'attribute',
              value: 'ETICHETTA',
            },
            {
              type: 'attribute',
              value: 'CATALOGO',
            },
          ],
        },
        ProductInfo: {
          header: {
            type: 'attribute',
            value: 'AUTORE',
          },
          body: {
            type: 'field',
            value: 'description',
          },
          details: [
            {
              type: 'field',
              value: 'productCategoryName',
            },
            {
              type: 'field',
              value: 'productSubcategoryName',
            },
            {
              type: 'field',
              value: 'sku',
            },
            {
              type: 'field',
              value: 'availableFrom',
            },
            {
              type: 'field',
              value: 'productBrandName',
            },
            {
              type: 'attribute',
              value: 'ETICHETTA',
            },
            {
              type: 'attribute',
              value: 'CATALOGO',
            },
          ],
          tabs: [
            {
              label: 'Descrizione',
              type: 'field',
              component: 'TextCard',
              value: 'extendedDescription',
              iconUrl: '',
            },
            // {
            //   label: 'Track List',
            //   type: 'attribute',
            //   component: 'ListCard',
            //   value: 'TRACKLIST',
            //   iconUrl: '',
            // },
            // {
            //   label: 'Media',
            //   type: 'attribute',
            //   component: 'IframeCard',
            //   value: ['SPOTIFY'],
            //   iconUrl: '',
            // },
          ],
        },
      },
      HOME_VIDEO: {
        CardProductHorizontal: {
          header: {
            type: 'attribute',
            value: 'AUTORE',
          },
          body: {
            type: 'field',
            value: 'description',
          },
          footerDetails: [
            {
              type: 'field',
              value: 'productCategoryName',
            },
            {
              type: 'field',
              value: 'productSubcategoryName',
            },
            {
              type: 'field',
              value: 'sku',
            },
            {
              type: 'field',
              value: 'availableFrom',
            },
            {
              type: 'field',
              value: 'productBrandName',
            },
            {
              type: 'attribute',
              value: 'ETICHETTA',
            },
            {
              type: 'attribute',
              value: 'CATALOGO',
            },
          ],
        },
        CardProductVertical: {
          header: {
            type: 'attribute',
            value: 'AUTORE',
          },
          body: {
            type: 'field',
            value: 'description',
          },
          footerDetails: [
            {
              type: 'field',
              value: 'productCategoryName',
            },
            {
              type: 'field',
              value: 'productSubcategoryName',
            },
            {
              type: 'field',
              value: 'sku',
            },
            {
              type: 'field',
              value: 'availableFrom',
            },
            {
              type: 'field',
              value: 'productBrandName',
            },
            {
              type: 'attribute',
              value: 'ETICHETTA',
            },
            {
              type: 'attribute',
              value: 'CATALOGO',
            },
          ],
        },
        CartProduct: {
          header: {
            type: 'attribute',
            value: 'AUTORE',
          },
          body: {
            type: 'field',
            value: 'description',
          },
          footerDetails: [
            {
              type: 'field',
              value: 'productCategoryName',
            },
            {
              type: 'field',
              value: 'productSubcategoryName',
            },
            {
              type: 'field',
              value: 'sku',
            },
            {
              type: 'field',
              value: 'availableFrom',
            },
            {
              type: 'field',
              value: 'productBrandName',
            },
            {
              type: 'attribute',
              value: 'ETICHETTA',
            },
            {
              type: 'attribute',
              value: 'CATALOGO',
            },
          ],
        },
        ProductInfo: {
          header: {
            type: 'attribute',
            value: 'AUTORE',
          },
          body: {
            type: 'field',
            value: 'description',
          },
          details: [
            {
              type: 'field',
              value: 'productCategoryName',
            },
            {
              type: 'field',
              value: 'productSubcategoryName',
            },
            {
              type: 'field',
              value: 'sku',
            },
            {
              type: 'field',
              value: 'availableFrom',
            },
            {
              type: 'field',
              value: 'productBrandName',
            },
            {
              type: 'attribute',
              value: 'ETICHETTA',
            },
            {
              type: 'attribute',
              value: 'CATALOGO',
            },
          ],
          tabs: [
            {
              label: 'Descrizione',
              type: 'field',
              component: 'TextCard',
              value: 'extendedDescription',
              iconUrl: '',
            },
            // {
            //   label: 'Track List',
            //   type: 'attribute',
            //   component: 'ListCard',
            //   value: 'TRACKLIST',
            //   iconUrl: '',
            // },
            // {
            //   label: 'Media',
            //   type: 'attribute',
            //   component: 'IframeCard',
            //   value: ['SPOTIFY'],
            //   iconUrl: '',
            // },
          ],
        },
      },
      HI_FI: {
        CardProductHorizontal: {
          header: {
            type: 'field',
            value: 'productBrandName',
          },
          body: {
            type: 'field',
            value: 'description',
          },
          footerDetails: [
            {
              type: 'field',
              value: 'productCategoryName',
            },
            {
              type: 'field',
              value: 'productSubcategoryName',
            },
            {
              type: 'field',
              value: 'sku',
            },
            {
              type: 'field',
              value: 'availableFrom',
            },
            {
              type: 'attribute',
              value: 'ETICHETTA',
            },
            {
              type: 'attribute',
              value: 'CATALOGO',
            },
          ],
        },
        CardProductVertical: {
          header: {
            type: 'field',
            value: 'productBrandName',
          },
          body: {
            type: 'field',
            value: 'description',
          },
          footerDetails: [
            {
              type: 'field',
              value: 'productCategoryName',
            },
            {
              type: 'field',
              value: 'productSubcategoryName',
            },
            {
              type: 'field',
              value: 'sku',
            },
            {
              type: 'field',
              value: 'availableFrom',
            },
            {
              type: 'attribute',
              value: 'ETICHETTA',
            },
            {
              type: 'attribute',
              value: 'CATALOGO',
            },
          ],
        },
        CartProduct: {
          header: {
            type: 'field',
            value: 'productBrandName',
          },
          body: {
            type: 'field',
            value: 'description',
          },
          footerDetails: [
            {
              type: 'field',
              value: 'productCategoryName',
            },
            {
              type: 'field',
              value: 'productSubcategoryName',
            },
            {
              type: 'field',
              value: 'sku',
            },
            {
              type: 'field',
              value: 'availableFrom',
            },
            {
              type: 'attribute',
              value: 'ETICHETTA',
            },
            {
              type: 'attribute',
              value: 'CATALOGO',
            },
          ],
        },
        ProductInfo: {
          header: {
            type: 'field',
            value: 'productBrandName',
          },
          body: {
            type: 'field',
            value: 'description',
          },
          details: [
            {
              type: 'field',
              value: 'productCategoryName',
            },
            {
              type: 'field',
              value: 'productSubcategoryName',
            },
            {
              type: 'field',
              value: 'sku',
            },
            {
              type: 'field',
              value: 'availableFrom',
            },
            {
              type: 'attribute',
              value: 'ETICHETTA',
            },
            {
              type: 'attribute',
              value: 'CATALOGO',
            },
          ],
          tabs: [
            {
              label: 'Descrizione',
              type: 'field',
              component: 'TextCard',
              value: 'extendedDescription',
              iconUrl: '',
            },
            // {
            //   label: 'Track List',
            //   type: 'attribute',
            //   component: 'ListCard',
            //   value: 'TRACKLIST',
            //   iconUrl: '',
            // },
            // {
            //   label: 'Media',
            //   type: 'attribute',
            //   component: 'IframeCard',
            //   value: ['SPOTIFY'],
            //   iconUrl: '',
            // },
          ],
        },
      },
      MERCHANDISE: {
        CardProductHorizontal: {
          header: {
            type: 'field',
            value: 'productBrandName',
          },
          body: {
            type: 'field',
            value: 'description',
          },
          footerDetails: [
            {
              type: 'field',
              value: 'productCategoryName',
            },
            {
              type: 'field',
              value: 'productSubcategoryName',
            },
            {
              type: 'field',
              value: 'sku',
            },
            {
              type: 'field',
              value: 'availableFrom',
            },
            {
              type: 'attribute',
              value: 'ETICHETTA',
            },
            {
              type: 'attribute',
              value: 'CATALOGO',
            },
          ],
        },
        CardProductVertical: {
          header: {
            type: 'field',
            value: 'productBrandName',
          },
          body: {
            type: 'field',
            value: 'description',
          },
          footerDetails: [
            {
              type: 'field',
              value: 'productCategoryName',
            },
            {
              type: 'field',
              value: 'productSubcategoryName',
            },
            {
              type: 'field',
              value: 'sku',
            },
            {
              type: 'field',
              value: 'availableFrom',
            },
            {
              type: 'attribute',
              value: 'ETICHETTA',
            },
            {
              type: 'attribute',
              value: 'CATALOGO',
            },
          ],
        },
        CartProduct: {
          header: {
            type: 'field',
            value: 'productBrandName',
          },
          body: {
            type: 'field',
            value: 'description',
          },
          footerDetails: [
            {
              type: 'field',
              value: 'productCategoryName',
            },
            {
              type: 'field',
              value: 'productSubcategoryName',
            },
            {
              type: 'field',
              value: 'sku',
            },
            {
              type: 'field',
              value: 'availableFrom',
            },
            {
              type: 'attribute',
              value: 'ETICHETTA',
            },
            {
              type: 'attribute',
              value: 'CATALOGO',
            },
          ],
        },
        ProductInfo: {
          header: {
            type: 'field',
            value: 'productBrandName',
          },
          body: {
            type: 'field',
            value: 'description',
          },
          details: [
            {
              type: 'field',
              value: 'productCategoryName',
            },
            {
              type: 'field',
              value: 'productSubcategoryName',
            },
            {
              type: 'field',
              value: 'sku',
            },
            {
              type: 'field',
              value: 'availableFrom',
            },
            {
              type: 'attribute',
              value: 'ETICHETTA',
            },
            {
              type: 'attribute',
              value: 'CATALOGO',
            },
          ],
          tabs: [
            {
              label: 'Descrizione',
              type: 'field',
              component: 'TextCard',
              value: 'extendedDescription',
              iconUrl: '',
            },
            // {
            //   label: 'Track List',
            //   type: 'attribute',
            //   component: 'ListCard',
            //   value: 'TRACKLIST',
            //   iconUrl: '',
            // },
            // {
            //   label: 'Media',
            //   type: 'attribute',
            //   component: 'IframeCard',
            //   value: ['SPOTIFY'],
            //   iconUrl: '',
            // },
          ],
        },
      },
      EDITORIA: {
        CardProductHorizontal: {
          header: {
            type: 'field',
            value: 'productBrandName',
          },
          body: {
            type: 'field',
            value: 'description',
          },
          footerDetails: [
            {
              type: 'field',
              value: 'productCategoryName',
            },
            {
              type: 'field',
              value: 'productSubcategoryName',
            },
            {
              type: 'field',
              value: 'sku',
            },
            {
              type: 'field',
              value: 'availableFrom',
            },
            {
              type: 'attribute',
              value: 'ETICHETTA',
            },
            {
              type: 'attribute',
              value: 'CATALOGO',
            },
          ],
        },
        CardProductVertical: {
          header: {
            type: 'field',
            value: 'productBrandName',
          },
          body: {
            type: 'field',
            value: 'description',
          },
          footerDetails: [
            {
              type: 'field',
              value: 'productCategoryName',
            },
            {
              type: 'field',
              value: 'productSubcategoryName',
            },
            {
              type: 'field',
              value: 'sku',
            },
            {
              type: 'field',
              value: 'availableFrom',
            },
            {
              type: 'attribute',
              value: 'ETICHETTA',
            },
            {
              type: 'attribute',
              value: 'CATALOGO',
            },
          ],
        },
        CartProduct: {
          header: {
            type: 'field',
            value: 'productBrandName',
          },
          body: {
            type: 'field',
            value: 'description',
          },
          footerDetails: [
            {
              type: 'field',
              value: 'productCategoryName',
            },
            {
              type: 'field',
              value: 'productSubcategoryName',
            },
            {
              type: 'field',
              value: 'availableFrom',
            },
            {
              type: 'field',
              value: 'sku',
            },
            {
              type: 'attribute',
              value: 'ETICHETTA',
            },
            {
              type: 'attribute',
              value: 'CATALOGO',
            },
          ],
        },
        ProductInfo: {
          header: {
            type: 'field',
            value: 'productBrandName',
          },
          body: {
            type: 'field',
            value: 'description',
          },
          details: [
            {
              type: 'field',
              value: 'productCategoryName',
            },
            {
              type: 'field',
              value: 'productSubcategoryName',
            },
            {
              type: 'field',
              value: 'sku',
            },
            {
              type: 'field',
              value: 'availableFrom',
            },
            {
              type: 'attribute',
              value: 'ETICHETTA',
            },
            {
              type: 'attribute',
              value: 'CATALOGO',
            },
          ],
          tabs: [
            {
              label: 'Descrizione',
              type: 'field',
              component: 'TextCard',
              value: 'extendedDescription',
              iconUrl: '',
            },
            // {
            //   label: 'Track List',
            //   type: 'attribute',
            //   component: 'ListCard',
            //   value: 'TRACKLIST',
            //   iconUrl: '',
            // },
            // {
            //   label: 'Media',
            //   type: 'attribute',
            //   component: 'IframeCard',
            //   value: ['SPOTIFY'],
            //   iconUrl: '',
            // },
          ],
        },
      },
      default: {
        CardProductHorizontal: {
          header: {
            type: 'attribute',
            value: 'AUTORE',
          },
          body: {
            type: 'field',
            value: 'description',
          },
          footerDetails: [
            {
              type: 'field',
              value: 'productCategoryName',
            },
            {
              type: 'field',
              value: 'productSubcategoryName',
            },
            {
              type: 'field',
              value: 'sku',
            },
            {
              type: 'field',
              value: 'availableFrom',
            },
            {
              type: 'field',
              value: 'productBrandName',
            },
            {
              type: 'attribute',
              value: 'ETICHETTA',
            },
            {
              type: 'attribute',
              value: 'CATALOGO',
            },
          ],
        },
        CardProductVertical: {
          header: {
            type: 'attribute',
            value: 'AUTORE',
          },
          body: {
            type: 'field',
            value: 'description',
          },
          footerDetails: [
            {
              type: 'field',
              value: 'productCategoryName',
            },
            {
              type: 'field',
              value: 'productSubcategoryName',
            },
            {
              type: 'field',
              value: 'sku',
            },
            {
              type: 'field',
              value: 'availableFrom',
            },
            {
              type: 'field',
              value: 'productBrandName',
            },
            {
              type: 'attribute',
              value: 'ETICHETTA',
            },
            {
              type: 'attribute',
              value: 'CATALOGO',
            },
          ],
        },
        CartProduct: {
          header: {
            type: 'attribute',
            value: 'AUTORE',
          },
          body: {
            type: 'field',
            value: 'description',
          },
          footerDetails: [
            {
              type: 'field',
              value: 'productCategoryName',
            },
            {
              type: 'field',
              value: 'productSubcategoryName',
            },
            {
              type: 'field',
              value: 'sku',
            },
            {
              type: 'field',
              value: 'availableFrom',
            },
            {
              type: 'field',
              value: 'productBrandName',
            },
            {
              type: 'attribute',
              value: 'ETICHETTA',
            },
            {
              type: 'attribute',
              value: 'CATALOGO',
            },
          ],
        },
        ProductInfo: {
          header: {
            type: 'attribute',
            value: 'AUTORE',
          },
          body: {
            type: 'field',
            value: 'description',
          },
          details: [
            {
              type: 'field',
              value: 'productCategoryName',
            },
            {
              type: 'field',
              value: 'productSubcategoryName',
            },
            {
              type: 'field',
              value: 'sku',
            },
            {
              type: 'field',
              value: 'availableFrom',
            },
            {
              type: 'field',
              value: 'productBrandName',
            },
            {
              type: 'attribute',
              value: 'ETICHETTA',
            },
            {
              type: 'attribute',
              value: 'CATALOGO',
            },
          ],
          tabs: [
            {
              label: 'Descrizione',
              type: 'field',
              component: 'TextCard',
              value: 'extendedDescription',
              iconUrl: '',
            },
            // {
            //   label: 'Track List',
            //   type: 'attribute',
            //   component: 'ListCard',
            //   value: 'TRACKLIST',
            //   iconUrl: '',
            // },
            // {
            //   label: 'Media',
            //   type: 'attribute',
            //   component: 'IframeCard',
            //   value: ['SPOTIFY'],
            //   iconUrl: '',
            // },
          ],
        },
      },
    },
  },

  getters: {
    layouts(state) {
      return state.layouts;
    },
  },
};
