import axios from 'axios';

export default {
  namespaced: true,

  state: {
    attributes: [],
  },

  getters: {
    attributes(state) {
      return state.attributes;
    },
  },

  mutations: {
    setAttributes(state, value) {
      state.attributes = value;
    },
  },

  actions: {
    async getAttributesByProductId({ commit }, productId) {
      const { data } = await axios.get(`/api/product/${productId}/attributes`);
      commit('setAttributes', data);
      return data;
    },
    async postAttributesByProductId({ commit }, { productId, attributes}) { // eslint-disable-line
      const { data } = await axios.post(`/api/product/${productId}/attributes`, { attributes });
      return data;
    },
    async getAttributesByVariantId({ commit }, variantId) {
      const { data } = await axios.get(`/api/product-variant/${variantId}/attributes`);
      commit('setAttributes', data);
      return data;
    },
    async postAttributesByVariantId({ commit }, { variantId, attributes}) { // eslint-disable-line
      const { data } = await axios.post(`/api/product-variant/${variantId}/attributes`, { attributes });
      return data;
    },
  },

};
