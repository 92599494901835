import axios from 'axios';

export default {
  namespaced: true,

  state: {
    customerGroups: [],
    customerGroup: {},
  },

  getters: {
    customerGroups(state) {
      return state.customerGroups;
    },
    customerGroup(state) {
      return state.customerGroup;
    },
  },

  mutations: {
    setCustomerGroups(state, value) {
      state.customerGroups = value;
    },
    setCustomerGroup(state, value) {
      state.customerGroup = value;
    },
  },

  actions: {
    async getCustomerGroups({ commit }) {
      const { data } = await axios.get('/api/customer-groups');
      commit('setCustomerGroups', data);
    },
    async postCustomerGroup({ commit }, customerGroup) {
      const { data } = await axios.post('/api/customer-groups', customerGroup);
      commit('setCustomerGroup', data);
    },
    async putCustomerGroup({ commit }, { id, ...remaining }) {
      const { data } = await axios.put(`/api/customer-groups/${id}`, remaining);
      commit('setCustomerGroup', data);
      return data;
    },
  },

};
