import axios from 'axios';

export default {
  namespaced: true,

  state: {
    warehouseTransactions: [],
    warehouseTransaction: {},
  },

  getters: {
    warehouseTransactions(state) {
      return state.warehouseTransactions;
    },
    warehouseTransaction(state) {
      return state.warehouseTransaction;
    },
  },

  mutations: {
    setWarehouseTransactions(state, value) {
      state.warehouseTransactions = value;
    },
    setWarehouseTransaction(state, value) {
      state.warehouseTransaction = value;
    },
  },

  actions: {
    async getWarehouseTransactions({ commit }) {
      const { data } = await axios.get('/api/warehouse-transactions');
      commit('setWarehouseTransactions', data);
    },
    async getWarehouseTransaction({ commit }, id) {
      const { data } = await axios.get(`/api/warehouse-transactions/${id}`);
      commit('setWarehouseTransaction', data);
    },
    async getWarehouseTransactionsBySku({ commit }, sku) {
      const { data } = await axios.get(`/api/warehouse-transactions?sku=${sku}`);
      commit('setWarehouseTransactions', data);
    },
  },
};
