import axios from 'axios';

export default {
  namespaced: true,

  state: {
    discountRules: [],
    discountRule: {},
  },

  getters: {
    discountRules(state) {
      return state.discountRules;
    },
    discountRule(state) {
      return state.discountRule;
    },
    // eslint-disable-next-line
    discountRuleByCustomerGroupAndProductCategory: (state) => (customerGroupId, productCategoryId) => {
      // eslint-disable-next-line
      return state.discountRules.filter((discountRule) =>  discountRule.customerGroupId === customerGroupId && discountRule.productCategoryId === productCategoryId);
    },
  },

  mutations: {
    setDiscountRules(state, value) {
      state.discountRules = value;
    },
    setDiscountRule(state, value) {
      state.discountRule = value;
    },
  },

  actions: {
    async getDiscountRules({ commit }) {
      const { data } = await axios.get('/api/discount-rules');
      commit('setDiscountRules', data);
    },
    async getDiscountRule({ commit }, id) {
      const { data } = await axios.get(`/api/discount-rules/${id}`);
      commit('setDiscountRule', data);
      return data;
    },
    async postDiscountRule({ commit }, discountRule) {
      const { data } = await axios.post('/api/discount-rules', discountRule);
      commit('setDiscountRule', data);
      return data;
    },
    async putDiscountRule({ commit }, { id, ...remaining }) {
      const { data } = await axios.put(`/api/discount-rules/${id}`, remaining);
      commit('setDiscountRule', data);
      return data;
    },
  },
};
