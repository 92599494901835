<template>
  <div :class="classes" class="hidden lg:flex justify-center w-1/4 xl:w-1/5">
    <div class="w-full flex flex-col justify-between rounded-lg shadow-lg bg-white
    dark:bg-slate-700 border p-8">
      <div class="flex flex-col items-center space-y-8">
        <router-link to="/" class="w-1/4 h-12 flex items-center">
          <img alt="logo" :src="navbarItems.logo" class="dark:hidden block" />
          <img alt="logo" :src="navbarItems.whiteLogo" class="hidden dark:block" />
        </router-link>
        <navbar-buttons />
        <DashboardCard v-for="item in items" :key="item"
        :label="item.label" :routes="item.routes"/>
      </div>
      <a href="https://www.egeanet.com/horizon/dashboard" target="_blank" class="w-fit">
        <button class="w-fit text-sm uppercase
        border rounded px-1 py-0.5 border-bee2_yellow hover:bg-bee2_yellow/60">
          gestione code
        </button>
      </a>
    </div>
  </div>

  <div class="block lg:hidden">
    <div class="flex w-full p-4">
      <button @click="openCloseNavbar()" class="z-50 lg:hidden absolute fill-black dark:fill-white">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 31 17" class="w-5">
          <defs></defs>
          <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
              <rect class="cls-1" x="1" y="1" width="18" height="1" rx="0.38" />
              <rect class="cls-1" x="1" y="15" width="29" height="1" rx="0.38" />
              <rect class="cls-1" x="1" y="8" width="23" height="1" rx="0.38" />
            </g>
          </g>
        </svg>
      </button>
      <navbar-buttons class="w-full justify-end"/>
    </div>

    <div class="w-full flex absolute justify-between">
      <transition name="slide-fade">
        <div class="flex absolute w-screen p-14 h-screen md:w-1/2 lg:w-1/4 shadow-inner
          space-y-5 z-40 bg-white dark:bg-slate-800" v-if="showNavbar">
          <div :class="mobileClasses">
            <router-link to="/" class="w-1/2" @click="openCloseNavbar()">
              <img alt="logo" :src="navbarItems.logo" class="w-full"/>
            </router-link>
            <div class="flex flex-col space-y-2">
              <base-navbar-link
                label="home"
                link-to="/"
                @click="openCloseNavbar()"
              />
              <!-- <base-navbar-link
                label="Prodotti"
                hasDropdown
                :dropdowns="productsLinks"
                @select="openCloseNavbar()"
                class="w-fit"
              /> -->
              <base-navbar-link
                label="Clienti"
                hasDropdown
                :dropdowns="customerLinks"
                @select="openCloseNavbar()"
                class="w-fit"
              />
              <base-navbar-link
                label="Ordini"
                hasDropdown
                :dropdowns="orderLinks"
                @select="openCloseNavbar()"
                class="w-fit"
              />
              <base-navbar-link
                label="Generale"
                hasDropdown
                :dropdowns="genericLinks"
                @select="openCloseNavbar()"
                class="w-fit"
              />
            </div>
          </div>
        </div>
      </transition>
      <button
        class="w-screen z-30 h-screen backdrop-blur-md lg:hidden cursor-default flex place-self-end"
        :class="showNavbar ? 'opacity-100 transition-opacity duration-500 ease-in-out' : 'opacity-0 hidden'"
        @click="openCloseNavbar()"
      >
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import BaseNavbarLink from '@/components/base/BaseNavbarLink.vue';
import appVariables from '@/config/appVariables.json';
import DashboardCard from './DashboardCard.vue';
import NavbarButtons from './NavbarButtons.vue';

export default {
  components: {
    BaseNavbarLink,
    DashboardCard,
    NavbarButtons,
  },
  data() {
    return {
      caseClass: {
        uppercase: 'uppercase',
        lowercase: 'lowercase',
        capitalize: 'capitalize',
      },
      weightClass: {
        light: 'font-light',
        normal: 'font-normal',
        bold: 'font-bold',
      },
      directionClass: {
        horizontal: 'flex flex-row space-x-4',
        vertical: 'flex flex-col space-y-2',
      },
      items: [
        {
          label: this.$t('backoffice.navbar.products'),
          routes: [
            {
              name: this.$t('backoffice.navbar.products'),
              path: '/backoffice/products',
            },
            {
              name: this.$t('backoffice.navbar.brands'),
              path: '/backoffice/brands',
            },
            {
              name: this.$t('backoffice.navbar.categories'),
              path: '/backoffice/categories',
            },
            {
              name: this.$t('backoffice.navbar.subcategories'),
              path: '/backoffice/subcategories',
            },
          ],
        },
        {
          label: this.$t('backoffice.navbar.customers'),
          routes: [
            {
              name: this.$t('backoffice.navbar.customerGroups'),
              path: '/backoffice/customer-groups',
            },
            {
              name: this.$t('backoffice.navbar.customers'),
              path: '/backoffice/customers',
            },
          ],
        },
        {
          label: this.$t('backoffice.navbar.orders'),
          routes: [
            {
              name: this.$t('backoffice.navbar.orders'),
              path: '/backoffice/orders',
            },
          ],
        },
        {
          label: this.$t('backoffice.navbar.general'),
          routes: [
            {
              name: this.$t('backoffice.navbar.discountRules'),
              path: '/backoffice/discount-rules',
            },
            {
              name: this.$t('backoffice.navbar.campaigns'),
              path: '/backoffice/campaigns',
            },
            {
              name: this.$t('backoffice.navbar.shippingMethods'),
              path: '/backoffice/shipping-methods',
            },
            {
              name: this.$t('backoffice.navbar.uoms'),
              path: '/backoffice/uoms',
            },
            {
              name: this.$t('backoffice.navbar.warehouses'),
              path: '/backoffice/warehouses',
            },
            {
              name: this.$t('backoffice.navbar.warehouseTransactions'),
              path: '/backoffice/warehouse-transactions',
            },
          ],
        },
      ],
      productsLinks: [
        {
          label: this.$t('backoffice.navbar.products'),
          link: '/backoffice/products',
        },
        {
          label: this.$t('backoffice.navbar.brands'),
          link: '/backoffice/brands',
        },
        {
          label: this.$t('backoffice.navbar.categories'),
          link: '/backoffice/categories',
        },
        {
          label: this.$t('backoffice.navbar.subcategories'),
          link: '/backoffice/subcategories',
        },
      ],
      customerLinks: [
        {
          label: this.$t('backoffice.navbar.customerGroups'),
          link: '/backoffice/customer-groups',
        },
        {
          label: this.$t('backoffice.navbar.customers'),
          link: '/backoffice/customers',
        },
      ],
      orderLinks: [
        {
          label: this.$t('backoffice.navbar.orders'),
          link: '/backoffice/orders',
        },
      ],
      genericLinks: [
        {
          label: this.$t('backoffice.navbar.discountRules'),
          link: '/backoffice/discount-rules',
        },
        {
          label: this.$t('backoffice.navbar.campaigns'),
          link: 'backoffice/campaigns',
        },
        {
          label: this.$t('backoffice.navbar.shippingMethods'),
          link: '/backoffice/shipping-methods',
        },
        {
          label: this.$t('backoffice.navbar.uoms'),
          link: '/backoffice/uoms',
        },
        {
          label: this.$t('backoffice.navbar.warehouses'),
          link: '/backoffice/warehouses',
        },
        {
          label: this.$t('backoffice.navbar.warehouseTransactions'),
          link: '/backoffice/warehouse-transactions',
        },
      ],
      showNavbar: false,
      navbarItems: appVariables,
    };
  },
  props: {
    fontCase: {
      type: String,
      default: 'uppercase',
    },
    direction: {
      type: String,
      default: 'horizontal',
    },
    mobileDirection: {
      type: String,
      default: 'vertical',
    },
    fontWeight: {
      type: String,
      default: 'light',
    },
    elementsColor: {
      type: String,
      default: 'text-black dark:text-white bg-white dark:bg-slate-800',
    },
    slideCart: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    this.startColorMode();
  },
  computed: {
    ...mapGetters('auth', ['authenticated', 'isSuperAdmin']),
    ...mapGetters('cart', ['productsCount']),
    classes() {
      // eslint-disable-next-line max-len
      return `${this.caseClass[this.fontCase]} ${this.directionClass[this.direction]} ${this.weightClass[this.fontWeight]} ${this.elementsColor}`;
    },
    mobileClasses() {
      // eslint-disable-next-line max-len
      return `${this.caseClass[this.fontCase]} ${this.directionClass[this.mobileDirection]} ${this.weightClass[this.fontWeight]} ${this.elementsColor}`;
    },
  },
  methods: {
    ...mapActions('auth', ['signOut']),
    openCloseNavbar() {
      this.showNavbar = !this.showNavbar;
    },
    startColorMode() {
      const lsTheme = localStorage.getItem('theme');
      this.setColorMode(lsTheme);
    },
    toggleDarkMode() {
      const lsTheme = localStorage.getItem('theme') === 'dark' ? 'light' : 'dark';
      this.setColorMode(lsTheme);
    },
    setColorMode(lsTheme) {
      const element = document.body;
      element.removeAttribute('class');
      element.classList.add(lsTheme);
      localStorage.setItem('theme', lsTheme);
    },
    async doAction() {
      if (!this.authenticated) {
        this.$router.push('/sign-in');
        return;
      }
      await this.signOut();
      this.$router.push('/');
    },
  },
};
</script>

<style>
.slide-fade-enter-active {
  transition: all 0.5s ease-in-out;
}

.slide-fade-leave-active {
  transition: all 0.5s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(-20px);
  opacity: 0;
}

</style>
