import axios from 'axios';

export default {
  namespaced: true,

  state: {
    variants: [],
    variant: {},
  },

  getters: {
    variants(state) {
      return state.variants;
    },
    variant(state) {
      return state.variant;
    },
  },

  mutations: {
    setVariants(state, value) {
      state.variants = value;
    },
    setVariant(state, value) {
      state.variant = value;
    },
  },

  actions: {
    async getVariantsByProductId({ commit }, productId) {
      const { data } = await axios.get(`/api/products/${productId}/variants`);
      commit('setVariants', data);
      return data;
    },
    async postVariantByProductId({ commit }, { productId, variant }) {
      const { data } = await axios.post(`/api/products/${productId}/variants`, variant);
      commit('setVariant', data);
      return data;
    },
    async putVariant({ commit }, { variantId, variant }) { // eslint-disable-line
      const { data } = await axios.put(`/api/variants/${variantId}`, variant);
      return data;
    },
    replaceVariant({ commit }, variant) {
      commit('setVariant', variant);
    },
  },

};
